import { Inject, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentChangeAction, DocumentData, QueryFn, QueryGroupFn } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This should be the only place where we should inject AngularFirestore.
 * This will minimize the impact when the
 * @angular/fire library gets updated.
 * Also, if at some point we want to change the library,
 * we will only need to update this class.
 */
@Injectable({
    providedIn: 'root',
})
export abstract class FirestoreService<T> {
    // Todo(rkara): Update the rest of the app to use  this.
    protected abstract basePath: string;

    get ref() {
        return this.firestore.collection(`${this.basePath}`).ref;
    }

    constructor(
        @Inject(AngularFirestore) protected firestore: AngularFirestore,
    ) { }

    doc$<T>(id: string, idField: string | null = 'id'): Observable<T | undefined> {
        return this.firestore.doc<T>(`${this.basePath}/${id}`).valueChanges({ idField });
    }

    exists(id: string): Observable<boolean> {
        return this.doc(id).get().pipe(map((item) => {
            return item.exists;
        }));
    }

    collectionGroup$(path: string, queryFn: QueryGroupFn<DocumentData>)
        : Observable<DocumentChangeAction<DocumentData>[]> {
        return this.firestore.collectionGroup(path, queryFn)
            .snapshotChanges();
    }

    collection$(queryFn?: QueryFn, options?: { idField: string; }): Observable<T[]> {
        return this.firestore.collection<T>(`${this.basePath}`, queryFn)
            .valueChanges(options);
    }

    create(value: T) {
        const id = this.createId();
        try {
            this.collection.doc(id).set(Object.assign({}, { id }, value));
            return id;
        } catch {
            return false;
        }
    }

    createId() {
        return this.firestore.createId();
    }

    update(id: string, dataToUpdate: { [key: string]: any }) {
        return from(this.collection.doc(id).update(dataToUpdate));
    }

    delete(id: string) {
        return this.collection.doc(id).delete();
    }

    get collection() {
        return this.firestore.collection(`${this.basePath}`);
    }

    doc<T>(id: string): AngularFirestoreDocument<T> {
        return this.firestore.collection<T>(`${this.basePath}`).doc(id);
    }
}
